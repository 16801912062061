import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref } from 'firebase/database';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const weddingDate = process.env.REACT_APP_WEDDING_DATE;
export const emailjs = {
    serviceId: process.env.REACT_APP_SERVICE_ID,
    templateId: process.env.REACT_APP_TEMPLATE_ID,
    publicKey: process.env.REACT_APP_PUBLIC_KEY
}

export const getData = async (path) => {
    try {
        const dbRef = ref(db, `info/${path}`);
        return await new Promise((resolve, reject) => {
            onValue(dbRef, (snapshot) => {
                const data = snapshot.val();
                resolve(data);
            }, (error) => {
                reject(error);
            })
        })
    } catch (err) {
        console.log("Issue fetching snapshot of database", err);
        throw err;
    }
}