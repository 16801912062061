import React, { Component } from 'react';
import storyPhoto from '../images/school-library-building.jpg';
import japanPhoto from '../images/first-engagement-photo.jpg';
import Navbar from './components/Navbar';
import Divider from './components/Divider';

class Story extends Component {
    componentDidMount() {
        const { handleDB } = this.props;
        handleDB('story');
    }

    render() {
        const { storyData, aboutUsData } = this.props;
        return (
            <div className="App">
                <Navbar/>
                <img src={japanPhoto} className="img-left" alt="engagement in Kyoto"/>
                <Divider/>
                <div className="App-details">
                    {/* Story Section */}
                    <p className="App-section-title">Story</p>
                    {/* Story Details */}
                    <p>{storyData.text}</p>
                    <img src={storyPhoto} className="img-center" alt="school library building"/>
                    {/* About Us Sub-Section */}
                    <p className="App-section-title">About Us</p>
                    {aboutUsData.map((about, index) => {
                        return (
                            <p key={index}>{about.text} <strong>{about.bold}</strong></p>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default Story;