import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './routes/Home';
import Story from './routes/Story';
// import Schedule from './routes/Schedule';
// import RSVP from './routes/RSVP';
// import Travel from './routes/Travel';
// import WeddingParty from './routes/WeddingParty';
// import Registry from './routes/Registry';
// import FAQ from './routes/FAQ';
import Contact from './routes/Contact';
import TBD from './routes/TBD';
// import Map from './routes/Map';
import { weddingDate, emailjs, getData } from './services/firebase';

class App extends Component {
  state = {
    homeData: {},
    storyData: {},
    aboutUsData: []
  }

  componentDidMount() {
    this.handleDB('home');
  }

  componentWillUnmount() {
    // 
  }

  handleDB = async (path) => {
    const data = await getData(path);
    if(path === 'home') {
      this.setState({
        homeData: data
      })
    } else if(path === 'story') {
      this.setState({
        storyData: data,
        aboutUsData: data.aboutUs
      })
    }
  }

  render() {
    const { homeData, storyData, aboutUsData } = this.state;
    return (
      <div>
        <Routes>
          <Route path="/">
            <Route index element={<Home homeData={homeData} weddingDate={weddingDate}/>}/>
            <Route path="story" element={<Story handleDB={this.handleDB} storyData={storyData} aboutUsData={aboutUsData}/>}/>
            <Route path="schedule" element={<TBD/>}/>
            <Route path="RSVP" element={<TBD/>}/>
            <Route path="travel" element={<TBD/>}/>
            <Route path="weddingparty" element={<TBD/>}/>
            <Route path="registry" element={<TBD/>}/>
            <Route path="FAQ" element={<TBD/>}/>
            <Route path="contact" element={<Contact emailjs={emailjs}/>}/>
            {/* <Route path="map" element={<Map/>}/> */}
          </Route>
        </Routes>
      </div>
    );
  }
}

export default App;
