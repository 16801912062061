import React from 'react';

class Form extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <form className="Contact-form" onSubmit={this.props.sendEmail} action="?" method="POST">
                <div className="Contact-fields">
                    <label className="Contact-label">Name</label>
                    <input className="Contact-input" type="text" name="user_name" required/>
                </div>
                <div className="Contact-fields">
                    <label className="Contact-label">Email</label>
                    <input className="Contact-input" type="email" name="user_email" required/>
                </div>
                <div className="Contact-fields">
                    <label className="Contact-label">Message</label>
                    <textarea className="Contact-input" name="message" required/>
                </div>
                {/* <div className="Contact-fields g-recaptcha" data-sitekey={siteKey}></div> */}
                <input className="Contact-submit" type="submit" value="Send"/>
            </form>
        )
    }
}

export default Form;